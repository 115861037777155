import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import ProductCategoriesContext from '../context/ProductCategoriesContext';

import Heading from '../atoms/Heading';

import HeadOverride from '../components/HeadOverride';
import ContentWrapper from '../components/ContentWrapper';
import SidebarLayout from '../components/SidebarLayout';
import Sidebar from '../components/Sidebar';
import SidebarActions from '../components/SidebarActions';
import SidebarInfo from '../components/SidebarInfo';
import BreadcrumbsBar from '../components/BreadcrumbsBar';
import ProductPreview from '../components/ProductPreview';
import CartButton from '../components/CartButton';
import Grid from '../components/Grid';
import ProductCategoriesDropdown from '../components/ProductCategoriesDropdown';

export default ({ data, pageContext }) => {
  const [currentCategory] = useContext(ProductCategoriesContext);
  const collection = data.allShopifyCollection.edges[0].node;

  const activeProducts =
    currentCategory === 'ALL'
      ? collection.products
      : collection.products.filter(product =>
          product.tags.includes(currentCategory)
        );

  return (
    <>
      <HeadOverride
        title={collection.title}
        description={collection.description}
        robots={pageContext.noindex ? 'noindex' : 'index'}
      />

      <BreadcrumbsBar
        breadcrumbs={pageContext.slugs}
        showHomeLink={!pageContext.isStandalone}
      />

      <SidebarLayout withBorders>
        <Sidebar>
          <SidebarActions>
            <ProductCategoriesDropdown />
          </SidebarActions>

          <SidebarInfo
            data={[
              {
                label: 'Inhalt',
                content: `${activeProducts.length} products`,
              },
            ]}
          />

          <CartButton />
        </Sidebar>

        <ContentWrapper withSidebar>
          <Heading headingSize={1} visuallyHidden>
            {collection.title}
          </Heading>

          <Grid>
            {activeProducts.map(product => {
              const images = product.images.slice(0, 2).map(img => ({
                fluid: img.localFile.childImageSharp.fluid,
                alt: img.altText,
              }));

              const price = parseFloat(product.variants[0].price);
              const priceCompare = product.variants[0].compareAtPrice
                ? parseFloat(product.variants[0].compareAtPrice)
                : null;

              const variants = product.variants.map(variant => ({
                key: variant.shopifyId,
                id: variant.shopifyId,
                isDisabled: variant.availableForSale === false,
                label: variant.selectedOptions[0].value,
              }));

              return (
                <ProductPreview
                  key={product.id}
                  url={`/${pageContext.handle}/${product.handle}/`}
                  isAvailable={product.availableForSale}
                  thumbnails={images}
                  name={product.title}
                  price={price}
                  priceCompare={priceCompare}
                  variants={variants}
                />
              );
            })}
          </Grid>
        </ContentWrapper>
      </SidebarLayout>
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    allShopifyCollection(filter: { handle: { eq: $handle } }) {
      edges {
        node {
          title
          description
          products {
            availableForSale
            id
            handle
            title
            tags
            variants {
              shopifyId
              availableForSale
              price
              compareAtPrice
              selectedOptions {
                value
              }
            }
            images {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
