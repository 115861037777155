import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';

import scale from '../../utils/scale';

import Button from '../../atoms/Button';
import GridItem from '../GridItem';
import Details from './Details';

const Product = styled(GridItem)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${scale(2)};

  ${props =>
    props.soldOut &&
    `
    opacity: 0.35;
  `}
`;

const HoverImgHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-out;
`;

const ThumbnailLink = styled(Link)`
  position: relative;
  display: block;
  margin-bottom: ${scale(0.75)};
  overflow: hidden;

  @media (hover: hover) {
    &:focus,
    &:hover {
      ${HoverImgHolder} {
        opacity: 1;
      }
    }
  }
`;

const ProductButton = styled(Button)`
  margin-top: ${scale(2)};
  align-self: flex-start;
`;

export default ({
  isAvailable,
  url,
  thumbnails,
  name,
  price,
  priceCompare = null,
  variants,
}) => {
  const [toggleInfo, setToggleInfo] = useState(false);

  const handleClick = e => {
    e.preventDefault();

    const scrollContainer = document.getElementById('scrollContainer');

    // navigate while saving the scroll position
    navigate(url, {
      state: { scrollPosition: scrollContainer.scrollTop || 0 },
    });
  };

  return (
    <Product w={[1 / 2, 1 / 2, 1 / 2, 1 / 3]} soldOut={isAvailable === false}>
      {thumbnails.length > 0 && (
        <ThumbnailLink
          to={url}
          onClick={handleClick}
          onMouseEnter={() => setToggleInfo(true)}
          onMouseLeave={() => setToggleInfo(false)}
        >
          <Img fluid={thumbnails[0].fluid} alt={thumbnails[0].alt} />

          {thumbnails.length > 1 && (
            <HoverImgHolder>
              <Img fluid={thumbnails[1].fluid} alt={thumbnails[1].alt} />
            </HoverImgHolder>
          )}
        </ThumbnailLink>
      )}

      <Details
        isAvailable={isAvailable}
        name={name}
        price={price}
        priceCompare={priceCompare}
        toggleInfo={toggleInfo}
        variants={variants}
      />

      <ProductButton
        label="View Product"
        useLink
        link={url}
        onClick={handleClick}
      />
    </Product>
  );
};
