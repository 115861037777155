import React from 'react';
import styled from 'styled-components';

import Heading from '../../atoms/Heading';

import scale from '../../utils/scale';
import media from '../../utils/mediaQueries';
import formatPrice from '../../utils/formatPrice';

const Container = styled.div`
  flex: 1 1 auto;
`;

const Title = styled(Heading).attrs({ headingSize: 3 })`
  margin-top: ${scale(0.25)};

  ${media.xsOnly`
    ${props =>
      props.hideOnMobile &&
      `
      display: none;
    `}
  `}
`;

const Text = styled.p`
  font-size: 11px;
  line-height: 14px;
  font-family: ${props => props.theme.font.extended};
  font-weight: bold;

  ${media.sm`
    font-size: 13px;
    line-height: 16px;
  `}
`;

const Price = styled.span`
  padding-right: ${scale(1)};

  ${props =>
    props.old &&
    `
    text-decoration: line-through;
    opacity: 0.4;
  `}
`;

const SecondaryInfo = styled.div`
  position: relative;
  height: ${scale(3)};
`;

const InfoItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.1s ease-out;
`;

const Variant = styled.span`
  display: inline-block;
  margin-right: ${scale(2)};
  opacity: ${props => (props.isDisabled ? 0.4 : 1)};
`;

export default ({
  name,
  isAvailable,
  price,
  priceCompare,
  variants,
  toggleInfo = false,
  ...props
}) => {
  let priceInfo;

  if (isAvailable) {
    priceInfo = priceCompare ? (
      <>
        <Price old>{formatPrice(priceCompare)}</Price>
        <Price>{formatPrice(price)}</Price>
      </>
    ) : (
      formatPrice(price)
    );
  } else {
    priceInfo = 'Sold out';
  }

  return (
    <Container {...props}>
      <Title hideOnMobile forwardedAs="p">
        Name
      </Title>
      <Text bold extended as="h3">
        {name}
      </Text>

      <SecondaryInfo>
        <InfoItem isActive={!toggleInfo || variants.length === 1}>
          <Title forwardedAs="p">Preis (incl. 19% VAT)</Title>
          <Text bold extended>
            {priceInfo}
          </Text>
        </InfoItem>

        {variants.length > 1 && (
          <InfoItem isActive={toggleInfo}>
            <Title forwardedAs="p">Größe/Size</Title>
            <Text bold extended truncate>
              {variants.map(({ key, label, isDisabled }) => (
                <Variant key={key} isDisabled={isDisabled}>
                  {label}
                </Variant>
              ))}
            </Text>
          </InfoItem>
        )}
      </SecondaryInfo>
    </Container>
  );
};
