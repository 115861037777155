import styled from 'styled-components';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

export default styled.div.attrs(({ w }) => ({
  w: w
    ? w.map(width => (width ? `${width * 100}%` : 'auto'))
    : ['auto', 'auto', 'auto', 'auto'],
}))`
  flex: 0 1 auto;
  padding: ${scale(1)};
  width: ${props => props.w[0]};

  ${media.sm`
    padding: ${scale(1.5)};
    width: ${props => props.w[1]};
  `}

  ${media.md`
    padding: ${scale(2)};
    width: ${props => props.w[2]};
  `}

  ${media.lg`
    width: ${props => props.w[3]};
  `}
`;
