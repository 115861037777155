import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import ProductCategoriesContext from '../context/ProductCategoriesContext';

import Icon from '../atoms/Icon';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

const labelStyle = css`
  font-size: 11px;
  line-height: 18px;
  font-family: ${props => props.theme.font.extended};
  color: ${props => props.theme.color.grayDark};
`;

const Container = styled.div`
  position: relative;

  /* pseudo first-letter since it's not directly possible on select */
  ::after {
    ${labelStyle};
    content: attr(data-letter);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid transparent;
    padding: 2px ${scale(1.75)} 0;
    height: ${props => scale(props.theme.height.buttonHeightMobile)};
    color: transparent;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.color.grayDark};
    pointer-events: none;

    ${media.md`
      padding: 2px ${scale(1.5)} 0;
      height: ${props => scale(props.theme.height.buttonHeight)};
    `};

    /* don't show in Firefox */
    @-moz-document url-prefix() {
      display: none;
    }
  }
`;

const SelectArrow = styled(Icon).attrs({ type: 'arrowDown' })`
  position: absolute;
  right: ${scale(1)};
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  pointer-events: none;
`;

const Select = styled.select.attrs({ as: 'select' })`
  ${labelStyle};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: 2px ${scale(3.5)} 0 ${scale(1.75)};
  height: ${props => scale(props.theme.height.buttonHeightMobile)};
  border-radius: ${props => props.theme.radius.base};
  cursor: pointer;
  outline: none;
  border-color: ${props => props.theme.color.borderMedium};
  background: ${props => props.theme.gradient.chromeLight};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${media.md`
    padding: 2px ${scale(3.75)} 0 ${scale(1.5)};
    height: ${props => scale(props.theme.height.buttonHeight)};
  `};

  :hover,
  :focus {
    background: ${props => props.theme.gradient.chromeLightHighlighted};
  }

  :active {
    background: ${props => props.theme.gradient.chromeLightInverted};
  }
`;

export default props => {
  const [currentCategory, setCategory, categoryOptions] = useContext(
    ProductCategoriesContext
  );

  const handleChange = e => {
    setCategory(e.target.value);
  };

  const currentOption = categoryOptions.filter(
    opt => opt.shopify_handle === currentCategory
  );

  return (
    categoryOptions && (
      <Container data-letter={currentOption[0].label.slice(0, 1)} {...props}>
        <Select value={currentCategory} onChange={handleChange}>
          {categoryOptions.map(option => (
            <option key={option.shopify_handle} value={option.shopify_handle}>
              {option.label}
            </option>
          ))}
        </Select>

        <SelectArrow />
      </Container>
    )
  );
};
