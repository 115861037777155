import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';


const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${scale(1)};

  ${media.sm`
    margin: -${scale(1.5)};
  `}

  ${media.md`
    margin: -${scale(2)};
  `}
`;


export default ({ children }) => (
  <Grid>
    {children}
  </Grid>
);
