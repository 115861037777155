import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import CartContext from '../context/CartContext';

import { GET_CHECKOUT } from '../data/checkout';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Button from '../atoms/Button';

const CartButtonHolder = styled.div`
  flex: 1 0 auto;
  padding-left: ${scale(2)};
  text-align: right;
  pointer-events: all;

  ${media.md`
    position: fixed;
    top: ${props =>
      scale(
        props.theme.height.chromeBarTop + props.theme.height.breadcrumbsBar
      )};
    right: ${scale(1.5)};
    padding: ${scale(0.5)} 1px 0 0;
  `}
`;

export default () => (
  <CartButtonHolder>
    <CartContext.Consumer>
      {({ checkoutId }) => (
        <>
          {checkoutId ? (
            <Query
              query={GET_CHECKOUT}
              variables={{ checkoutId }}
              partialRefetch
            >
              {({ loading, data }) => {
                let buttonLabel = 'Cart';

                if (!loading) {
                  const qtyInCart = data.node.lineItems.edges.reduce(
                    (acc, { node }) => acc + node.quantity,
                    0
                  );

                  buttonLabel = qtyInCart > 0 ? `Cart (${qtyInCart})` : 'Cart';
                }

                return (
                  <Button
                    secondary
                    icon="cartLight"
                    label={buttonLabel}
                    useLink
                    link="/shop/cart/"
                  />
                );
              }}
            </Query>
          ) : (
            <Button
              secondary
              icon="cartLight"
              label="Cart"
              useLink
              link="/shop/cart/"
            />
          )}
        </>
      )}
    </CartContext.Consumer>
  </CartButtonHolder>
);
